.gform_fields {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  .ginput_container {
    input {
      width: 80%;
      height: 40px;
      padding: 0;
      border: none;
      padding-left: 10px;
      color: #333;
    }
  }
}

.gfield_label {
  display: none;
}

.gform_footer {
  margin-top: 15px;
  input[type=submit] {
    line-height: 1;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 10px 13px;
    text-transform: uppercase;
    font-size: 16px;
    transition: all .3s ease-in-out;
  }
}

.gform_embed {
  margin: auto;

  .gform_confirmation_message {
    text-align: center;
  }

  .gform_wrapper {
    margin: auto;

    .gform_body {
      .gfield_label {
        display: none;

      }
      li.gfield {
        margin: auto;

        .ginput_container {
          margin: auto;
        }
      }
    }
    .gform_footer {
      text-align: center;
      padding-bottom: 0;
      input[type=submit] {
        display: inline-block;
        margin: auto;
        max-width: 40%;
      }
    }
  }

}