@import url('https://fonts.googleapis.com/css?family=Karla');

.brandastic-landing-row {
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
}

.brandastic-landing-content {
  width: 640px;
  text-align: center;
  //background : rgba(28,28,28,.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2b2b2b;
  opacity: 0.75;
  border-radius: 5px;
}

.brandastic-landing-content .content {
  font-family: Karla, sans-serif;
  color: #fff;
  padding: 50px;
  padding-top: 15px;
  padding-bottom: 20px;
}

.brandastic-landing-content .tagline {
  font-size: 20px;
  padding-bottom: 20px;
}

.brandastic-landing-content .logo-container img {
  padding: 20px 0;
}

.brandastic-landing-content .logo-container {
  border-bottom: 2px solid #464646;

  img {
    max-width: 200px;

  }
}

.gravity_form {
  margin-top: 20px;
}

.social {
  text-align: center;
  margin-top: 20px;
  .social-link {
    display: inline-block;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .icon {
      font-size: 45px;
      color: #fff;
      transition: all .3s ease-in-out;
    }
  }
  .social-link:not(:last-of-type) {
    margin-right: 15px;
  }
}

.tagline-bottom {
  line-height: 1;
  margin-top: 15px;
  font-size: 20px;
}
