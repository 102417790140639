$font-path: "/wp-content/plugins/brandastic-landing/fonts";

$icon-instagram: "\e902";
$icon-twitter: "\e802";
$icon-facebook: "\e803";

@font-face {
  font-family: 'brandasticlanding';
  src:
          url('#{$font-path}/brandasticlanding.woff2?hun2n2') format('woff2'),
          url('#{$font-path}/brandasticlanding.ttf?hun2n2') format('truetype'),
          url('#{$font-path}/brandasticlanding.woff?hun2n2') format('woff'),
          url('#{$font-path}/brandasticlanding.svg?hun2n2#brandastic') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'brandasticlanding' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

